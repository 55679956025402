<template>
    <v-container grid-list-xl fluid>
        <section v-if="IsAdmin">
            <AltaHistorico :id="id"/>
        </section>
    </v-container>
</template>


<script>
import userService from "@/services/UserServices";
import AltaHistorico from "../../components/cliente/AltaHistorico.vue";
export default {
    props: {
        id: {
            type: Number,
            require: true
        }
    },
    components:{
        AltaHistorico,
    },
    computed: {
        IsAdmin() {
            return userService.IsAdmin();
        }
    }
}
</script>


<style scoped>
    
</style>