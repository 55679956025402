import api from "./api";

const Historico = {
    cl2_codigo: -1,
    cl2_item: 0,
    cl2_codthc: 0,
    cl2_fecha: "Sin definir",
    cl2_descri: "Sin definir",
    cl2_tiempo: 0,
    cl2_import: 0,
    cl2_fecalt: "Sin definir",
    cl2_usualt: 0,
    cl2_estado: 0,
    thc_descri: "Sin definir",
    est_descri: "Sin definir",
    usu_descri: "Sin definir"
}

Historico.consultar = async(id) => {
    return api.get('/Cliente/Historico/' + id).then(res => res.data);
}

Historico.agregar = async(nuevoHistorico) =>{
    return api.post('Cliente/Historico', nuevoHistorico)
}

Historico.Editar = async(nuevoHistorico) =>{
    api.put('Cliente/Historico', nuevoHistorico)
}



export default Historico;