import api from "./api";

const TipoHistorico = {
    thc_codigo: 0,
    thc_descri: "Sin Definir",
    thc_orden: 0,
    thc_estado: 0
}

TipoHistorico.consultar = async() => {
    return api.get('/Tipo/ClienteHistorico').then(res => res.data);
}


export default TipoHistorico;